import './App.css';
import React, { useEffect, useState } from "react";
import About from './components/About';
import Cursor from './components/Cursor';
import Navbar from './components/Navbar';
import Typing from './components/Typing';
import avatar from './images/avatar.webp';
import Tilt from 'react-parallax-tilt';
import Maze from './components/Three/Maze';
import Education from './components/Education';
import Hr from './components/Hr';
import 'aos/dist/aos.css';
import Skills from './components/Skills';
import Projects from './components/Projects';
import ProjectModal from './components/ProjectModal';
import Contact from './components/Contact';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Loading from './components/Loading';

function App() {

  const [modal, setModal] = useState({ show: false, title: "", text: "", images: [], tech: [], links: [] })
  const [theme, setTheme] = useState("light");
  const [appLoading, setAppLoading] = useState(true);

  function showModal({ title, text, images, tech, links }) {
    setModal({ show: true, title, text, images, tech, links })
  }

  function hideModal() {
    setModal({ show: false, title: '', text: '', images: [], tech: [], links: [] })
  }

  useEffect(() => {
    const loadingElem = document.querySelector(".loading-main");
    if (loadingElem) {
      loadingElem.classList.remove("loading");
      loadingElem.classList.add("hide-app");
    }
  }, []) 

  return (
    <div className={(theme === 'dark' ? "App dark-theme" : "App")}>

      {
        appLoading && <Loading />
      }

      <div className={appLoading ? "hide-app" : "app-content"}>
        <Maze mode={theme} setAppLoading={setAppLoading} />

        <Navbar mode={theme} setTheme={setTheme} />

        <div className='home-wrapper' id="home">
          <div className="home">
            <div className="hello">
              <h2 className='hello-im'>Hello, I'm</h2>
              <h1 className="name">Yohan Sandun</h1>
              <Typing />
              <div className='social'>
                <a className='linkedin' rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/yohan-sandun/"><LinkedInIcon /></a>
                <a className='github' rel="noreferrer" target="_blank" href="https://github.com/YohanSandun"><GitHubIcon /></a>
                <a className='whatsapp' rel="noreferrer" target="_blank" href="https://wa.me/94714266758"><WhatsAppIcon /></a>
                <a className='youtube' rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UC9kIHYq0QS_L1Q1ez52j9gA"><YouTubeIcon /></a>
                <a className='facebook' rel="noreferrer" target="_blank" href="https://www.facebook.com/yohan.sandun.5"><FacebookIcon /></a>
              </div>
            </div>
            <div className="avatar-holder">
              <Tilt >
                <img className='avatar' src={avatar} alt="Yohan Sandun" />
              </Tilt>
            </div>
          </div>
        </div>

        <Hr />

        <About />

        <Hr />

        <Education />

        <Hr />

        <Skills />

        <Hr />

        <div className='space'></div>

        <Hr />

        <Projects showModal={showModal} />

        <Hr />

        <Contact />

        <ProjectModal modal={modal} hideModal={hideModal} />

        <Cursor />
      </div>
    </div>
  );

}

export default App;
