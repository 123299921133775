import React, { useEffect } from "react";
import './Education.css';
import SchoolIcon from '@mui/icons-material/School';
import AOS from 'aos';

function Education() {

    useEffect(() => {
        AOS.init({duration: 500})
    }, [])

    return (
        <div className="education-wrapper" id="education">
            <div className="education">
                <div className="left">

                </div>
                <div className="right">
                    <h2><SchoolIcon />Education</h2>

                    <div className="outer">
                        <div className="card">
                            <div className="info glass" data-aos="slide-left" data-aos-offset="50">
                                <b className="text-muted">2020 - PRESENT</b>
                                <h3 className="title">Bachelor of Information and Communication Technology (Hons)</h3>
                                <p>University of Sri Jayewardenepura</p>
                                <p>Current GPA: 3.91</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="info glass" data-aos="slide-right" data-aos-offset="50">
                                <b className="text-muted">2019</b>
                                <h3 className="title">Diploma in English</h3>
                                <p>Yapa Foundation - Matara</p>
                                <p>Passed</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="info glass" data-aos="slide-left" data-aos-offset="50">
                                <b className="text-muted">2019</b>
                                <h3 className="title">Diploma in ICT</h3>
                                <p>Yapa Foundation - Matara</p>
                                <p>Passed</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="info glass" data-aos="slide-right" data-aos-offset="50">
                                <b className="text-muted">2016 - 2018</b>
                                <h3 className="title">GCE Advanced Level - Technology Stream</h3>
                                <p>G/Neluwa National School</p>
                                <p>Passed with 2A, B. All Island 86<sup>th</sup> and District 6<sup>th</sup> (Galle)</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="info glass" data-aos="slide-left" data-aos-offset="50">
                                <b className="text-muted">2014 - 2015</b>
                                <h3 className="title">GCE Ordinary Level</h3>
                                <p>G/Neluwa National School</p>
                                <p>Passed with 4A, 4B, and C.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Education;