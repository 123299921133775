import * as THREE from 'three';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import React, { useEffect, useRef } from 'react';
import './Maze.css';

function Maze({ mode, setAppLoading }) {
    const canvas = useRef();

    let myReq;

    useEffect(() => {
        setupThree();
        return () => {
            window.cancelAnimationFrame(myReq);
        }
        // eslint-disable-next-line
    }, [mode]);

    const setupThree = () => {
        let scrollPercent = 0
        const scene = new THREE.Scene({ antialias: true });

        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.001, 1000);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);
       
        const renderer = new THREE.WebGLRenderer({ canvas: canvas.current });
        renderer.setPixelRatio( window.devicePicelRatio );
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.toneMapping = THREE.ACESFilmicToneMapping;

        renderer.setSize(window.innerWidth, window.innerHeight);
        if (mode === 'light') {
            renderer.setClearColor(0xcce6ff, 1);
            directionalLight.color.set(0xffffff);
            scrollPercent =
                ((document.documentElement.scrollTop || document.body.scrollTop) /
                    ((document.documentElement.scrollHeight ||
                        document.body.scrollHeight) -
                        document.documentElement.clientHeight)) *
                100;
        } else {
            renderer.setClearColor(0x00264d, 1);
            directionalLight.color.set(0x777777);
            scrollPercent =
                ((document.documentElement.scrollTop || document.body.scrollTop) /
                    ((document.documentElement.scrollHeight ||
                        document.body.scrollHeight) -
                        document.documentElement.clientHeight)) *
                100;
        }
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        directionalLight.position.x = 40;
        directionalLight.position.y = 100;
        directionalLight.position.z = 100;

        directionalLight.shadow.mapSize.width = 4096; // default
        directionalLight.shadow.mapSize.height = 4096; // default
        directionalLight.shadow.camera.near = 0.1; // default
        directionalLight.shadow.camera.far = 200; // default
        directionalLight.shadow.radius = 5
        directionalLight.shadow.blurSamples = 25
        directionalLight.castShadow = true;

        scene.add(directionalLight);


        // soft white light
        //scene.add(light);
        var mixer = null;

        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('./draco/');

        let maze;

        const loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        loader.load(
            // resource URL
            './models/maze.gltf',
            // called when the resource is loaded
            function (gltf) {
                maze = gltf.scene;
                maze.scale.set(0.2, 0.2, 0.2);

                gltf.scene.traverse( function ( child ) {
                    if ( child.isMesh ) {
                        if ( child.name === 'Clouds' ) {
                            child.castShadow = true;
                        } else if ( child.name === 'Ocean' ) {
                            child.receiveShadow = true;
                        } 
                        else {
                            child.castShadow = true;
                            child.receiveShadow = true;
                        }
                    }
        
                } );

                // for (let i = 0; i < maze.children.length; i++) {
                //     if (maze.children[i].name === 'Airplane' ||
                //         maze.children[i].name === 'Airplane001' ||
                //         maze.children[i].name === 'Airplane002' ||
                //         maze.children[i].name === 'Clouds') {
                //         maze.children[i].castShadow = true;
                //     }
                //     else if (maze.children[i].name === 'Ocean') {
                //         maze.children[i].receiveShadow = true;
                //     } else {
                //         maze.children[i].castShadow = true; //default is false
                //         maze.children[i].receiveShadow = true; //default
                //     }
                // }

                scene.add(maze);

                mixer = new THREE.AnimationMixer(maze);
                for (let i = 0; i < gltf.animations.length; i++) {
                    //if (gltf.animations[i].name === 'Action') {
                    var action = mixer.clipAction(gltf.animations[i]);
                    action.play();
                    //}
                }

                // gltf.scene; // THREE.Group
                // gltf.scenes; // Array<THREE.Group>
                // gltf.cameras; // Array<THREE.Camera>
                // gltf.asset; // Object
                console.log("Loadedededed");
                setAppLoading(false);
            },
            // called while loading is progressing
            function (xhr) {
                console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            },
            // called when loading has errors
            function (error) {
                console.log('An error happened');
            }
        );

        window.addEventListener('resize', onWindowResize, false)
        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight
            camera.updateProjectionMatrix()
            renderer.setSize(window.innerWidth, window.innerHeight)
            renderer.render(scene, camera);
        }

        function lerp(x, y, a) {
            return (1 - a) * x + a * y
        }

        // Used to fit the lerps to start and end at specific scrolling percentages
        function scalePercent(start, end) {
            return (scrollPercent - start) / (end - start)
        }

        const animationScripts = [];

        camera.position.x = 0;
        camera.position.y = 0;
        camera.rotation.set(-1.57, 0, 0);

        animationScripts.push({
            start: 3,
            end: 37,
            func: () => {
                camera.position.x = lerp(0.2, 0.3, scalePercent(1, 60))
                camera.position.y = lerp(0.2, 0.3, scalePercent(1, 60))
                camera.lookAt(scene.position)
                //console.log(camera.position.x + " " + camera.position.y)
            },
        })
        animationScripts.push({
            start: 37,
            end: 70,
            func: () => {
                camera.position.x = lerp(0.2, 0.9, scalePercent(35, 60))
                camera.position.y = lerp(0.2, 0.9, scalePercent(35, 60))
                camera.lookAt(scene.position)
                //console.log(camera.position.x + " " + camera.position.y)
            },
        })


        function playScrollAnimations() {
            animationScripts.forEach((a) => {
                if (scrollPercent >= a.start && scrollPercent < a.end) {
                    a.func()
                }
            })
        }

        

        document.addEventListener('scroll', () => {
            scrollPercent =
                ((document.documentElement.scrollTop || document.body.scrollTop) /
                    ((document.documentElement.scrollHeight ||
                        document.body.scrollHeight) -
                        document.documentElement.clientHeight)) *
                100;
        });

        function animate() {
            myReq = requestAnimationFrame(animate);
            playScrollAnimations();
            //cube.rotation.x += 0.01;
            //cube.rotation.y += 0.01;
            //camera.rotation.y += 0.001;

            if (maze && mixer) {
                maze.rotation.y += 0.001;
                maze.rotation.x += 0.001;
                maze.rotation.z += 0.001;

                mixer.update(0.01);
            }

            renderer.render(scene, camera);
        }

        animate();
    }

    return (
        <canvas ref={canvas} className='maze'></canvas>
    );
}

export default Maze;