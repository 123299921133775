import React, { useEffect } from "react";
import './Skills.css';
import WebIcon from '@mui/icons-material/Web';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MonitorIcon from '@mui/icons-material/Monitor';
import JavascriptIcon from '@mui/icons-material/Javascript';
import PhpIcon from '@mui/icons-material/Php';
import StarIcon from '@mui/icons-material/Star';
import VerifiedIcon from '@mui/icons-material/Verified';
import { ReactComponent as ReactIcon } from '../images/react.svg';
import { ReactComponent as NodeIcon } from '../images/node.svg';
import { ReactComponent as MysqlIcon } from '../images/sql.svg';
import { ReactComponent as MongoIcon } from '../images/mongo.svg';
import { ReactComponent as FirebaseIcon } from '../images/firebase.svg';
import { ReactComponent as FlutterIcon } from '../images/flutter.svg';
import { ReactComponent as JavaIcon } from '../images/java.svg';
import { ReactComponent as KotlinIcon } from '../images/kotlin.svg';
import { ReactComponent as CppIcon } from '../images/cpp.svg';
import { ReactComponent as CsIcon } from '../images/cs.svg';
import { ReactComponent as PythonIcon } from '../images/python.svg';
import { ReactComponent as BlenderIcon } from '../images/blender.svg';
import { ReactComponent as UnityIcon } from '../images/unity.svg';
import { ReactComponent as PsIcon } from '../images/ps.svg';
import AOS from 'aos';

function Skills() {

    useEffect(() => {
        AOS.init({duration: 500})
    }, [])

    return (
        <div className="skills-wrapper" id="skills">
            <h2><VerifiedIcon /> Skills</h2>
            <div className="skills">

                <div className="skill-set glass" data-aos="fade-up" data-aos-delay="100">
                    <WebIcon />
                    <h2>Web Development</h2>
                    <ul>
                        <li><div className="icon-wrapper"><JavascriptIcon /></div> JavaScript</li>
                        <li><div className="icon-wrapper"><ReactIcon /></div> React</li>
                        <li><div className="icon-wrapper"><PhpIcon /></div> PHP</li>
                        <li><div className="icon-wrapper"><NodeIcon /></div> Node + Express</li>
                        <li><div className="icon-wrapper"><MysqlIcon /></div> MySQL</li>
                        <li><div className="icon-wrapper"><MongoIcon /></div> MongoDB</li>
                    </ul>
                </div>

                <div className="skill-set glass" data-aos="fade-up" data-aos-delay="200">
                    <PhoneAndroidIcon />
                    <h2>Mobile Development</h2>
                    <ul>
                        <li><div className="icon-wrapper"><JavaIcon /></div> Java</li>
                        <li><div className="icon-wrapper"><FlutterIcon /></div> Flutter</li>
                        <li><div className="icon-wrapper"><KotlinIcon /></div> Kotlin</li>
                    </ul>
                </div>

                <div className="skill-set glass" data-aos="fade-up" data-aos-delay="300">
                    <MonitorIcon />
                    <h2>Desktop Development</h2>
                    <ul>
                        <li><div className="icon-wrapper"><CsIcon /></div> C# .NET</li>
                        <li><div className="icon-wrapper"><JavaIcon /></div> Java</li>
                        <li><div className="icon-wrapper"><PythonIcon /></div> Python</li>
                        <li><div className="icon-wrapper"><CppIcon /></div> C++</li>
                    </ul>
                </div>

                <div className="skill-set glass" data-aos="fade-up" data-aos-delay="400">
                    <StarIcon />
                    <h2>Other</h2>
                    <ul>
                        <li><div className="icon-wrapper"><FirebaseIcon /></div> Firebase</li>
                        <li><div className="icon-wrapper"><BlenderIcon /></div> Blender</li>
                        <li><div className="icon-wrapper"><UnityIcon /></div> Unity</li>
                        <li><div className="icon-wrapper"><PsIcon /></div> Photoshop</li>
                    </ul>
                </div>

            </div>
        </div>
    );
}

export default Skills;