import React from "react";
import './Navbar.css';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import VerifiedIcon from '@mui/icons-material/Verified';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import CallIcon from '@mui/icons-material/Call';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

function Navbar({mode, setTheme}) {
    return (
        <div className="navbar">
            <a className="navbar-icon" title="Home" href="#home"><HomeIcon /></a>
            <a className="navbar-icon" title="About Me" href="#about"><InfoIcon /></a>
            <a className="navbar-icon" title="Education" href="#education"><SchoolIcon /></a>
            <a className="navbar-icon" title="Skills" href="#skills"><VerifiedIcon /></a>
            <a className="navbar-icon" title="Projects" href="#projects"><WysiwygIcon /></a>
            <a className="navbar-icon" title="Contact" href="#contact"><CallIcon /></a>
            <p className="navbar-icon theme" title="Theme" onClick={() => {
                if (mode==='light') {
                    setTheme("dark");
                } else {
                    setTheme("light");
                }
            }}>{mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}</p>
        </div>
    );
}

export default Navbar;