import React, { useEffect } from "react";
import './About.css';
import avatar2 from '../images/avatar2.webp';
import Tilt from 'react-parallax-tilt';
import InfoIcon from '@mui/icons-material/Info';
import AOS from 'aos';

function About() {

    useEffect(() => {
        AOS.init({duration: 500})
    }, [])

    return (
        <div className="about-wrapper" id="about">

            <div className="about">

                <Tilt >
                    <img data-aos="flip-right" className='avatar2' src={avatar2} alt="Yohan Sandun" />
                </Tilt>

                <div className="glass about-me">
                    <h2><InfoIcon />About Me</h2>
                    <p className="about-me-fp" data-aos="zoom-in">
                        I'm an undergraduate at the University of Sri Jayewardenepura Faculty of Technology.
                        I'm following a Bachelor of Information and Communication Technology Honors degree.
                        I'm interested in Software Development, Mobile Development, and Web Development.
                    </p>
                    <p data-aos="zoom-in" data-aos-delay="100">
                        I have several years of experience in Software development, Mobile development, and Web development.
                        I have done some very successful software projects for several clients.
                        As well as I have published several mobile apps on Google PlayStore.
                    </p>
                </div>
            </div>

        </div>
    );
}

export default About;