import { TypeAnimation } from 'react-type-animation';
import './Typing.css';

function Typing() {
    return (
        <TypeAnimation
            sequence={[
                'Full Stack Web Developer', // Types 'One'
                1000, // Waits 1s
                'Desktop Developer', // Deletes 'One' and types 'Two'
                1000, // Waits 2s
                'Mobile Developer', // Types 'Three' without deleting 'Two'
            ]}
            wrapper="span"
            cursor={true}
            repeat={Infinity}
            className='typing'
        />
    )
}

export default Typing;