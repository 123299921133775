import React, { useEffect, useRef } from 'react';
import './Cursor.css';

function Cursor() {

    const delay = 12;

    var cursorVisible = false;
    var cursorEnlarged = false;
    var endX = window.innerWidth/2;
    var endY = window.innerHeight/2;
    //var oldY = endY;
    var _x = 0;
    var _y = 0;

    const dot = useRef(null);
    const outline = useRef(null);

    const toggleCursorVisibility = () => {
        if (cursorVisible) {
            dot.current.style.opacity = 1;
            outline.current.style.opacity = 1;
        } else {
            dot.current.style.opacity = 0;
            outline.current.style.opacity = 0;
        }
    }

    const toggleCursorSize = () => {
        if (dot == null || outline == null) {
            return;
        }

        if (cursorEnlarged) {
            dot.current.style.transform = 'translate(-50%, -50%) scale(0.75)';
            outline.current.style.transform = 'translate(-50%, -50%) scale(1.5)';
        } else {
            dot.current.style.transform = 'translate(-50%, -50%) scale(1)';
            outline.current.style.transform = 'translate(-50%, -50%) scale(1)';
        }
    }

    const animateOutline = () => {
        if (outline == null) {
            return;
        }
        
        _x += (endX - _x) / delay;
        _y += (endY - _y) / delay;
        outline.current.style.top = _y + 'px';
        outline.current.style.left = _x + 'px';
        
        requestAnimationFrame(animateOutline.bind(this));
    }

    const setupEvents = () => {
        document.addEventListener('mousemove', function(e) {
            if (dot == null) {
                return;
            }

            endX = e.pageX;
            endY = e.pageY;
            //oldY = endY;
            dot.current.style.top = endY + 'px';
            dot.current.style.left = endX + 'px';
        });
    
        document.addEventListener('mouseenter', function(e) {
            if (dot == null || outline == null) {
                return;
            }

            cursorVisible = true;
            toggleCursorVisibility();
            dot.current.style.opacity = 1;
            outline.current.style.opacity = 1;
        });
        
        document.addEventListener('mouseleave', function(e) {
            if (dot == null || outline == null) {
                return;
            }

            cursorVisible = true;
            toggleCursorVisibility();
            dot.current.style.opacity = 0;
            outline.current.style.opacity = 0;
        });

        document.addEventListener('mousedown', () => {
            cursorEnlarged = true;
            toggleCursorSize();
        });

        document.addEventListener('mouseup', () => {
            cursorEnlarged = false;
            toggleCursorSize();
        });

        // window.addEventListener("scroll", (e) => {
        //     let scroll = document.body.getBoundingClientRect().top;
        //     endY = oldY - scroll;
        //     dot.current.style.top = endY + 'px';
        // })
    }

    useEffect(() => {
        setupEvents();
        animateOutline();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div ref={outline} className='cursor-dot-outline'></div>
            <div ref={dot} className='cursor-dot'></div>
        </>
    )
}

export default Cursor;