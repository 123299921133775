import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularProgressBar() {
  return (
    <div className='content-middle'>
      <h2>Please Wait...</h2>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </div>

  );
}