import React from "react";
import Tilt from 'react-parallax-tilt';
import SearchIcon from '@mui/icons-material/Search';

function Project({image, item, showModal, white}) {
    return (
        <Tilt>
            <div className="div">
                <img src={image} alt={item.title} />
                <p className={"image-desc" + (white ? " white" : " black")} onClick={() => showModal(item)}><SearchIcon /> {item.title}</p>
            </div>
        </Tilt>
    );
}

export default Project;