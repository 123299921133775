import React from "react";
import "./Projects.css";
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import Akuru from '../images/projects/akuru.webp';
import Upg from '../images/projects/upg.webp';
import Upg1 from '../images/projects/upg_job.webp';
import Upg2 from '../images/projects/upg_jobs.webp';
import Upg3 from '../images/projects/upg_pos.webp';
import Upg4 from '../images/projects/upg_prdcts.webp';
import Upg5 from '../images/projects/upg_settings.webp';
import CashBook from '../images/projects/cash_book.webp';
import PenTech from '../images/projects/pentech.webp';
import PenTech1 from '../images/projects/pt_inv.webp';
import PenTech2 from '../images/projects/pt_items.webp';
import PenTech3 from '../images/projects/pt_stat.webp';
import TextBooks from '../images/projects/textbooks.webp';
import Sr1 from '../images/projects/sr1.webp';
import Sr2 from '../images/projects/sr2.webp';
import Sr3 from '../images/projects/sr3.webp';
import Rcmd1 from '../images/projects/rcmd1.webp';
import Rcmd2 from '../images/projects/rcmd2.webp';
import Sfix1 from '../images/projects/sfix1.webp';
import Sfix2 from '../images/projects/sfix2.webp';
import Sfix3 from '../images/projects/sfix3.webp';
import Sigiri from '../images/projects/sigiri.webp';
import SrAndorid from '../images/projects/sr_android.webp';
import GithubCloner from '../images/projects/github.webp';
import EpicEats from '../images/projects/epiceats.webp';
import Gpa from '../images/projects/gpa.webp';
import Gpa1 from '../images/projects/gpa1.webp';
import Gpa2 from '../images/projects/gpa2.webp';
import Gpa3 from '../images/projects/gpa3.webp';
import Gpa4 from '../images/projects/gpa4.webp';
import AvrSim from '../images/projects/avrsim.webp';
import SharpE from '../images/projects/sharpe.webp';
import Ascii from '../images/projects/ascii.webp';
import GPACalc from '../images/projects/gpacalc.webp';

import Project from "./Project";

/*
{
        title: "",
        text: "",
        tech: [
            { tech: '', cls: '' }, 
            { tech: '', cls: '' }
        ],
        links: [
            { type: 'ps', href: "" }, 
            { type: 'web', href: "" }
        ],
        images: [
            {
                src: '',
                alt: ""
            },
        ]
    }
*/

const my_projects = {
    akuru: {
        title: "Akuru Keyboard",
        text: "Akuru is a feature packed soft keyboard application for Android. Which allows you to type in English, Sinhalese and Tamil. Currently this app have over 30,000+ installs on Google Play.",
        tech: [{ tech: 'Android', cls: 'android' }, { tech: 'Java', cls: 'java' }],
        links: [{ type: 'ps', href: "https://play.google.com/store/apps/details?id=lk.ysk.akuru" }, { type: 'web', href: "https://akurukb.com" }],
        images: [
            {
                src: 'https://play-lh.googleusercontent.com/YvrBm99N8Cm_k4Mo34g6ProkWuZIuWSri4sXHLDb47uOTK_J_85R2Mm8F3zmMRyC9nc=w2560-h1440-rw',
                alt: "Features"
            },
            {
                src: 'https://play-lh.googleusercontent.com/4PwD4ojnKmuhdEJ4R9uVplV1EsekxZki45DTr-ygugfpr8C7ve4i19WwckcihOPCR_I=w2560-h1440-rw',
                alt: "Akuru Keyboard"
            },
            {
                src: 'https://play-lh.googleusercontent.com/v7dCdHvGaiwNRj_mnyiJrLdJCKALw-ZdVnJLBn4quX2t5Hxg4e8buPPo2NlrhAOOuCOw=w2560-h1440-rw',
                alt: "Akuru Keyboard"
            },
            {
                src: 'https://play-lh.googleusercontent.com/JQhkFCytDP0tWgB77Y5VWugnzsJaFn6g2XsGCdC6kAIAnZOQhz6-g_47NzPBSAp0Pg=w2560-h1440-rw',
                alt: "Akuru Keyboard"
            },
            {
                src: 'https://play-lh.googleusercontent.com/efE2N5JIBauWBzMlovIQvluj3anLzfgqelXjNTK733xR2rv31QTbKeSxSTUm7GB7x_I=w2560-h1440-rw',
                alt: "Akuru Keyboard"
            }
        ]
    },
    upg: {
        title: "UPG Mobile POS",
        text: "A fully equipped Point of Sale and Job System for a mobile phone repair shop called UPG Mobile located in Galle, Sri Lanka. The application is web based and written in PHP and pure HTML, CSS and JavaScript. The system is capable of handling daily transactions and analytics.",
        tech: [
            { tech: 'PHP', cls: 'php' },
            { tech: 'JavaScript', cls: 'js' },
            { tech: 'MySQL', cls: 'mysql' },
            { tech: 'HTML', cls: 'html' },
            { tech: 'CSS', cls: 'css' },
        ],
        links: [],
        images: [
            {
                src: Upg,
                alt: 'Dashboard'
            },
            {
                src: Upg1,
                alt: 'Job'
            },
            {
                src: Upg2,
                alt: 'Jobs'
            },
            {
                src: Upg3,
                alt: 'POS'
            },
            {
                src: Upg4,
                alt: 'Products'
            },
            {
                src: Upg5,
                alt: 'Settings'
            },
        ]
    },
    cashbook: {
        title: "Cash Book",
        text: "Cash Book is a user-friendly android app that lets users manage their daily cash transactions with ease. With its cloud-based nature, multiple user support, and image and description addition feature, Cash Book ensures that all data is preserved and accessible even if the device is lost. It's the perfect tool for small businesses, families, or individuals who want to stay on top of their financial activities effortlessly.",
        tech: [
            { tech: 'Android', cls: 'android' }, 
            { tech: 'Java', cls: 'java' },
            { tech: 'Firebase', cls: 'firebase' },
        ],
        links: [
            { type: 'ps', href: "https://play.google.com/store/apps/details?id=lk.ysk.cashbook" }
        ],
        images: [
            {
                src: 'https://play-lh.googleusercontent.com/nSjcUm3nwRmJAXtedQDb_WKjCWEL0W0SsX5WIBnsaiqX2sVEE0ZaisnBdduMG2WIDrA=w2560-h1440-rw',
                alt: 'Login Screen',
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/0a1oAXXFk0iUCW05H_YbCHCxUZUfhiCSPBLGTwLg4qoNDmc-soSiANpdOtKx-oTyEw=w2560-h1440-rw',
                alt: 'Cash Books',
                phone: true
            },
            {
                src: CashBook,
                alt: "Transactions",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/NGo6fctr0AK5aSYKuYIzl0NoqYYCFvGUkeVbDxYsaX69zfTH1zCy-Q78cC94to5mrEw=w2560-h1440-rw',
                alt: 'Multi-user',
                phone: true
            },
        ]
    },
    pentech: {
        title: "PenTech POS",
        text: "The PenTech POS system is a powerful retail management software built using C# .NET and MySQL for the database. It was developed for a client from Galle, Sri Lanka. It streamlines daily transactions and inventory management while providing valuable analytics to improve business performance. The system is secure, scalable, and an efficient solution for retail computer shops.",
        tech: [
            { tech: 'C#', cls: 'csharp' }, 
            { tech: 'MySQL', cls: 'mysql' }
        ],
        links: [],
        images: [
            {
                src: PenTech,
                alt: "Dashboard"
            },
            {
                src: PenTech1,
                alt: "Invoice"
            },
            {
                src: PenTech2,
                alt: "Items"
            },
            {
                src: PenTech3,
                alt: "Statics"
            },
        ]
    },
    textbooks: {
        title: "Text Books",
        text: "Text Books app has almost every free book in Sri Lankan public education system in Sinhala, Tamil and English. Users can share books as PDF files and PDF files are available to download separately as well. There are two seperate apps for Sinhala language and Other languages.",
        tech: [
            { tech: 'Android', cls: 'android' }, 
            { tech: 'Java', cls: 'java' },
            { tech: 'Firebase', cls: 'firebase' },
        ],
        links: [
            { type: 'ps', href: "https://play.google.com/store/apps/details?id=lk.ysk.textbooks_si" }, 
            { type: 'ps', href: "https://play.google.com/store/apps/details?id=lk.ysk.textbooks" }
        ],
        images: [
            {
                src: TextBooks,
                alt: 'Text Books',
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/QFtK4S60Ij1v8-BJpQTs2vnEL9GcYG5LqCj5j0L_suh60ZbkwiP57oAgrSzKw9EYTw=w2560-h1440-rw',
                alt: 'Text Books',
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/QEupbb39yuB3iCnJ8DRWFguFMIHuNoqjWyGoRTOMVm_8YEMMHsPurjDTA36_ySYrvA=w2560-h1440-rw',
                alt: 'Text Books',
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/tmKevIJoEDgzPI61lRjLQ9EK9sTHhgNYpRqEw3wRtrctnUMBmFCEm2qlJj4vAUkEPo7g=w2560-h1440-rw',
                alt: 'Text Books',
                phone: true
            },
        ]
    },
    subrenderer: {
        title: "SubRenderer - Windows",
        text: "SubRenderer is a versatile subtitle converter application that allows users to convert .srt subtitle files into pre-rendered VobSub (.sub, .idx) files. With the added feature of merging the rendered subtitle into a video file, users can play videos on any device, regardless of its ability to support Unicode characters. SubRenderer's powerful functionality streamlines the subtitle conversion process, making it a valuable tool for anyone trying to see Unicode subtitles on unsupported devices.",
        tech: [
            { tech: 'C#', cls: 'csharp' },
        ],
        links: [
            { type: 'git', href: "https://github.com/YohanSandun/SubRenderer" }, 
        ],
        images: [
            {
                src: Sr1,
                alt: "SubRenderer"
            },
            {
                src: Sr2,
                alt: "SubRenderer"
            },
            {
                src: Sr3,
                alt: "SubRenderer"
            },
        ]
    },
    rcmd: {
        title: "RCMD",
        text: "Powerful command line tool to save and run commands from anywhere. Available for both Windows and Linux.",
        tech: [
            { tech: 'C++', cls: 'cpp' }, 
        ],
        links: [
            { type: 'git', href: "https://github.com/YohanSandun/rcmd" }, 
        ],
        images: [
            {
                src: Rcmd1,
                alt: "RCMD"
            },
            {
                src: Rcmd2,
                alt: "Commands File"
            },
        ]
    },
    subfix: {
        title: "SubFix",
        text: "In Windows 11 there is an issue with Sinhala Unicode characters. This piece of software can fix those issues in subtitle files.",
        tech: [
            { tech: 'C#', cls: 'csharp' }, 
        ],
        links: [
            { type: 'git', href: "https://github.com/YohanSandun/sub-fix" }, 
        ],
        images: [
            {
                src: Sfix1,
                alt: "SubFix"
            },
            {
                src: Sfix2,
                alt: "SubFix"
            },
            {
                src: Sfix3,
                alt: "SubFix"
            },
        ]
    },
    sigiri: {
        title: "Sigiri",
        text: "Sigiri is a simple, light-weight, object-oriented, and interpreted programming laguage. Sigiri combines wider range of facilitie from other popular programming languages.",
        tech: [
            { tech: 'C#', cls: 'csharp' }, 
            { tech: 'C++', cls: 'cpp' }
        ],
        links: [
            { type: 'git', href: "https://github.com/YohanSandun/Sigiri" }, 
        ],
        images: [
            {
                src: Sigiri,
                alt: 'Sigiri'
            },
        ]
    },
    srandroid: {
        title: "SubRenderer - Android",
        text: "SubRenderer is a versatile subtitle converter application that allows users to convert .srt subtitle files into pre-rendered VobSub (.sub, .idx) files. With the added feature of merging the rendered subtitle into a video file, users can play videos on any device, regardless of its ability to support Unicode characters. SubRenderer's powerful functionality streamlines the subtitle conversion process, making it a valuable tool for anyone trying to see Unicode subtitles on unsupported devices.",
        tech: [
            { tech: 'Android', cls: 'android'}, 
            { tech: 'Java', cls: 'java'}, 
        ],
        links: [
            { type: 'ps', href: "https://play.google.com/store/apps/details?id=lk.ysk.subrenderer" },
        ],
        images: [
            {
                src: 'https://play-lh.googleusercontent.com/GRswMwnd59J2VK_m2V4jsb5gdjw86nB1jzpusknSPh_TQrpQQDrVqO87Zhke2V8gPA=w2560-h1440-rw',
                alt: "Homepage",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/6IcnJ-IvFD-WFhYo6gDXPaTAU4n_4A4op9NSNiDDDhcGsEdTUZRrP5xr8D_ljm4j0w=w2560-h1440-rw',
                alt: "SubRenerer",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/WBBtEG2ukphawY9z1l1ViVehJrraCbkpU5fuC1FDDFm0W59Rc_PW4Cxq6hpszW3UxTo=w2560-h1440-rw',
                alt: "SubRenerer",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/U4e5zwRdvR8D1c0TRd7v9sReMM-0wp45q6ziQTwMv-v6CMNWhyfohYqfo50i4z70ag=w2560-h1440-rw',
                alt: "SubRenerer",
                phone: true
            },
        ]
    },
    githubcloner: {
        title: "GitHub Cloner",
        text: "GitHub Cloner is a tool that users can use to browse GitHub faster and anonymously. Users can clone repositories as Zip, Tar or individual files. Users can manage their favorite repositories as well.",
        tech: [
            { tech: 'Android', cls: 'android' }, 
            { tech: 'Java', cls: 'java' }
        ],
        links: [
            { type: 'ps', href: "https://play.google.com/store/apps/details?id=lk.ysk.githubcloner" }, 
            { type: 'git', href: "https://github.com/YohanSandun/GitHubCloner" }
        ],
        images: [
            {
                src: GithubCloner,
                alt: "GithubCloner",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/eCqAqzdqXQiYf0kQCHsEQ-WT0s3uYHv9kcGoFjs7q15f2GKea9S0LXFFXKSxCiHE0RM=w2560-h1440-rw',
                alt: "GithubCloner",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/0lqDt9fO5d-UyyFkLgL8v6rm1rQgNjaJdHDR6vIOZpj2-pLKPlYq3AuG0rakhpg_u7w=w2560-h1440-rw',
                alt: "GithubCloner",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/ubkMTVQUljplainhMBHssrVXTshO0vxf-pvA98BL0_tyt3lEl4-tBIs2phSaNjHMWooh=w2560-h1440-rw',
                alt: "GithubCloner",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/OMjnr0EHMQ1GbQYKVcAtEknqA2biQ1IqmzLCQ8OnExJE1BfvVSKWpaOesD_ItzIFFxo=w2560-h1440-rw',
                alt: "Searching",
                phone: true
            },
        ]
    },
    epiceats: {
        title: "Epic Eats - Group Project",
        text: "Epic Eats is a food ordering application. The app allows users to customize their foods using a 3D interactive environment. The app is intergrated with Payhere payment method as well. I was responsible for developing the backend of this application.",
        tech: [
            { tech: 'Flutter', cls: 'flutter' }, 
            { tech: 'Dart', cls: 'dart' },
            { tech: 'Three.js', cls: 'js' },
            { tech: 'PHP', cls: 'php' },
        ],
        links: [ 
            { type: 'git', href: "" }
        ],
        images: [
            {
                src: 'https://play-lh.googleusercontent.com/haNf5QaBqzujGf3y-mpzliegnzxmW_Tpn1fHvQbRRz3tX7xyN6FbUgQp65Ih17rUGtxK=w2560-h1440-rw',
                alt: "Epic Eats",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/eCvIZYl1RLIdvfwoudGWkeE5_kA7FHJTxupaQj--kH9ZstVvTaj3LXjbBhct2LOzyg=w2560-h1440-rw',
                alt: "3D food customization",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/CFM1ujEd0_vUN7bTinCVFJxs-N5TVZY6KrmXu3bPj7XQ4E9WpfiqSGuRJyR0X4qbpZI=w2560-h1440-rw',
                alt: "Menu",
                phone: true
            },
        ]
    },
    gpa: {
        title: "GPA",
        text: "Automatic GPA calculation from result sheets. I created this app so my collegues can enter their index numbers to the system and check their CGPA realtime. At the end of each semester I update the data using result sheets. Updating process is automated using a Python script. I've created a browser extension as well, so students can check their GPA directly from the LMS Dashboard.",
        tech: [
            { tech: 'PHP', cls: 'php' }, 
            { tech: 'JavaScript', cls: 'js' },
            { tech: 'Python', cls: 'python' },
            { tech: 'MySQL', cls: 'mysql' },
            { tech: 'HTML', cls: 'html' },
            { tech: 'CSS', cls: 'css' },
        ],
        links: [
            { type: 'web', href: "https://akurukb.com/gpa" },
            { type: 'ext', href: "https://microsoftedge.microsoft.com/addons/detail/bogoplaijchcdolckgonijfboeejkgeh" }
        ],
        images: [
            {
                src: Gpa1,
                alt: "Search"
            },
            {
                src: Gpa2,
                alt: "GPA"
            },
            {
                src: Gpa3,
                alt: "Semester Results"
            },
            {
                src: Gpa4,
                alt: "Browser Extension"
            },

        ]
    },
    avrsim: {
        title: "AVRSim",
        text: "AVR microcontroller simulator written in C#",
        tech: [
            { tech: 'C#', cls: 'csharp' }, 
        ],
        links: [
            { type: 'git', href: "https://github.com/YohanSandun/AVRSim" }, 
        ],
        images: [
            {
                src: AvrSim,
                alt: "AVRSim"
            },
        ]
    },
    sharpengine: {
        title: "Sharp Engine",
        text: "Simple 3D renderer using System.Drawing in C#",
        tech: [
            { tech: 'C#', cls: 'csharp' }, 
        ],
        links: [
            { type: 'git', href: "https://github.com/YohanSandun/SharpEngine" }, 
        ],
        images: [
            {
                src: SharpE,
                alt: "Sharp Engine"
            },
        ]
    },
    ascii: {
        title: "Ascii Effect",
        text: "Ascii Effect is a photo editor which can convert your photographs into a piece of art just by using some English characters. Users can export their arts as images or HTML files.",
        tech: [
            { tech: 'Android', cls: 'android' }, 
            { tech: 'Java', cls: 'java' }
        ],
        links: [
            { type: 'ps', href: "https://play.google.com/store/apps/details?id=lk.ysk.asciieffect" }, 
        ],
        images: [
            {
                src: Ascii,
                alt: "Ascii Effect"
            },
            {
                src: 'https://play-lh.googleusercontent.com/gPRUQvU7CiNqjIkKpfTGmQfkt5NySy0ozqF4WT2HxKxDAN0TIuM1OuGnY5cPlnbfiljP=w2560-h1440-rw',
                alt: "Ascii Effect",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/iZx3wQ1lWWNkSJtkl4wKLz3ObuHGEZryjURChCwC5cxtdupllylHAJ74QKkqetqm8Do=w2560-h1440-rw',
                alt: "Ascii Effect",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/GabI-P9_GtTObC0eHL8GwwwaOeXdPwfvIsikE_XkcpQ0uJA_ouFHBWOiSzsOyGhPHXU=w2560-h1440-rw',
                alt: "Ascii Effect",
                phone: true
            },
        ]
    },
    gpacalc: {
        title: "GPA Calculator",
        text: "GPA Calculator is a tool that can be used to calculate Grade Point Average(GPA). The app is cabale of calculating and keeping multiple student's GPA values.",
        tech: [
            { tech: 'Android', cls: 'android' }, 
            { tech: 'Java', cls: 'java' }
        ],
        links: [
            { type: 'ps', href: "https://play.google.com/store/apps/details?id=lk.ysk.gpacalc" },
        ],
        images: [
            {
                src: GPACalc,
                alt: "GPA Calc"
            },
            {
                src: 'https://play-lh.googleusercontent.com/iOAMYMM_VvAM06uhVD-yyAaOzR0rB2ikId5_KlUIYmle32LqHz26aY8sdEyMkdGZJ-g=w2560-h1440-rw',
                alt: "GPA",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/C8vOYldRPhC6dn_iLjwvwLpiq_sCHfbnb5Lf2wEQZfgkM8FC-PSZgoTBXh14KAlD7inr=w2560-h1440-rw',
                alt: "GPA",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/ip9BJiw-BTdJwkK3fnDgwrGxiCoM1f9eTl6YwKD6v1Z3ZHkodneQKvf2dtHYIio20w=w2560-h1440-rw',
                alt: "GPA",
                phone: true
            },
            {
                src: 'https://play-lh.googleusercontent.com/9s-w3FrPo2nGQ7TeHfLZ5NLsN_0XribyxOhJWFpIQhOKvvrCM8KyroT6Qe1lZB5Fgp4=w2560-h1440-rw',
                alt: "GPA",
                phone: true
            },
        ]
    }
};

function Projects({ showModal }) {
    return (
        <div className="projects-wrapper" id="projects">
            <h2><WysiwygIcon /> Projects</h2>
            <div className="projects">
                <div>
                    <Project image={Akuru} item={my_projects.akuru} showModal={showModal} white={true} />
                    <Project image={TextBooks} item={my_projects.textbooks} showModal={showModal} />
                    <Project image={GithubCloner} item={my_projects.githubcloner} showModal={showModal} />
                    <Project image={Ascii} item={my_projects.ascii} showModal={showModal} />
                </div>
                <div>
                    <Project image={Upg} item={my_projects.upg} showModal={showModal} />
                    <Project image={PenTech} item={my_projects.pentech} showModal={showModal} white={true} />
                    <Project image={Sr1} item={my_projects.subrenderer} showModal={showModal} />
                    <Project image={Rcmd1} item={my_projects.rcmd} showModal={showModal} white={true} />
                    <Project image={Sigiri} item={my_projects.sigiri} showModal={showModal} white={true} />
                    <Project image={EpicEats} item={my_projects.epiceats} showModal={showModal} />
                    <Project image={AvrSim} item={my_projects.avrsim} showModal={showModal} />
                    <Project image={GPACalc} item={my_projects.gpacalc} showModal={showModal} white={true} />
                </div>
                <div>
                    <Project image={CashBook} item={my_projects.cashbook} showModal={showModal} />
                    <Project image={Sfix1} item={my_projects.subfix} showModal={showModal} />
                    <Project image={SrAndorid} item={my_projects.srandroid} showModal={showModal} />
                    <Project image={Gpa} item={my_projects.gpa} showModal={showModal} />
                    <Project image={SharpE} item={my_projects.sharpengine} showModal={showModal} />
                </div>
            </div>
        </div>
    );
}

export default Projects;