import React, { useState } from "react";
import './ProjectModal.css'
import CancelIcon from '@mui/icons-material/Cancel';
import LanguageIcon from '@mui/icons-material/Language';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import PlayStore from '../images/ps.webp';
import GitHubIcon from '@mui/icons-material/GitHub';
import ExtensionIcon from '@mui/icons-material/Extension';

function ProjectModal({ modal, hideModal }) {

    const [closing, setClosing] = useState(false);

    const modelClass = modal.show ? "model show" : "model hide";

    const closeModal = (e) => {
        e.stopPropagation()
        setClosing(true);
        setTimeout(() => {
            hideModal();
            setClosing(false);
        }, 200);
    }

    return (
        <div className={modelClass} onClick={(e) => closeModal(e)}>
            <div className={"model-window" + (closing ? " model-close" : "")} onClick={(e) => e.stopPropagation()}>
                <h2>{modal.title}</h2>
                <div className="model-content">
                    <div className="carousel">
                        <Carousel dynamicHeight={true} width="100%">
                            {
                                modal.images.map((item, index) => (
                                    <div key={index}>
                                        <img src={item.src} alt={item.alt} className={item.phone && "phone"} />
                                    </div>
                                ))
                            }
                        </Carousel>
                    </div>
                    <div className="details">
                        <p>{modal.text}</p>
                        <div className="tech">
                            {
                                modal.tech.map((item, index) => (
                                    <div key={index} className={item.cls}>{item.tech}</div>
                                ))
                            }
                        </div>
                        <div className="links">
                            {
                                modal.links.map((item, index) => {
                                    if (item.type === 'ps') {
                                        return <div key={index}><a key={index} className="btn-ps" rel="noreferrer" target="_blank" href={item.href}><img src={PlayStore} alt="Get it on Google Play"/></a></div>
                                    }
                                    else if (item.type === 'web') {
                                        return <a key={index} rel="noreferrer" target="_blank" href={item.href}><LanguageIcon /> Visit Website</a>
                                    }
                                    else if (item.type === 'git') {
                                        return <a key={index} rel="noreferrer" target="_blank" href={item.href}><GitHubIcon /> View on GitHub</a>
                                    }
                                    else if (item.type === 'ext') {
                                        return <a key={index} rel="noreferrer" target="_blank" href={item.href}><ExtensionIcon /> Extension</a>
                                    }
                                    return <a key={index} rel="noreferrer" className='btn' arget="_blank" href={item.href}>Visit</a>
                                })
                            }
                        </div>
                    </div>
                </div>
                <CancelIcon onClick={(e) => closeModal(e)} />
            </div>

        </div>
    );
}

export default ProjectModal;