import React, { useState, useRef } from "react";
import './Contact.css';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SendIcon from '@mui/icons-material/Send';
import CircularProgressBar from "./CircularProgressBar";

function SubmitError() {
    return (
        <div className="content-middle">
            <p style={{ color: 'red' }}>Failed to submit. Please try again.</p>
        </div>
    );
}

function SubmitSuccess() {
    return (
        <div className="content-middle">
            <p style={{ color: 'var(--color-primary)' }}>Your message record successfully. Thank you!</p>
        </div>
    );
}


function Contact() {

    const [form, setForm] = useState({ name: '', email: '', message: '' });
    const [errors, setErrors] = useState({ name: false, email: false, message: false });
    const formElement = useRef(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [error, setError] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setForm((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
        if (errors[name]) {
            if (form[name].trim().length > 0) {
                setErrors({
                    ...errors,
                    [name]: false
                });
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (form.name.trim().length === 0) {
            setErrors({
                ...errors,
                name: true
            });
            return;
        }
        if (form.email.trim().length === 0) {
            setErrors({
                ...errors,
                email: true
            });
            return;
        }
        if (form.message.trim().length === 0) {
            setErrors({
                ...errors,
                message: true
            });
            return;
        }

        setLoading(true);
        submit();
    };

    const submit = () => {
        var formData = new FormData(formElement.current);
        postData('./post_msg.php', formData)
            .then((data) => {
                setSuccess(true);
                setError(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(true);
                setSuccess(false);
                setLoading(false);
                setTimeout(() => {
                    setLoading(false);
                    setError(false);
                    setSuccess(false);
                }, 3000)
            });
    }

    async function postData(url = '', data) {
        const response = await fetch(url, {
            method: 'POST',
            body: data
        });
        return response.json();
    }

    return (
        <div className="contact-wrapper" id="contact">
            <div className="contact">
                <div className="basic-contact">
                    <a href="mailto:yohan.sandunk@gmail.com">
                        <div className="glass">
                            <EmailIcon />
                            <h2>Email</h2>
                            <p>yohan.sandunk@gmail.com</p>
                        </div>
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://wa.me/94714266758">
                        <div className="glass">
                            <LocalPhoneIcon />
                            <h2>Phone</h2>
                            <p>+94 714 266 758</p>
                        </div>
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://www.google.com/maps/place/YSK+Soft+Corporation/@6.3653485,80.3641892,18.56z/data=!4m6!3m5!1s0x3ae3d92b5ef4005d:0x8b911774678c7571!8m2!3d6.3652313!4d80.3646905!16s%2Fg%2F11b6dps3xl">
                        <div className="glass">
                            <LocationOnIcon />
                            <h2>Address</h2>
                            <p>11/1 A1, Medagama, Neluwa, Galle.</p>
                        </div>
                    </a>
                </div>

                <div className="contact-form">
                    {loading ? <CircularProgressBar /> : (error ? <SubmitError /> : (success ? <SubmitSuccess /> :
                        <form onSubmit={handleSubmit} ref={formElement}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><label htmlFor="name">Your Name</label></td>
                                        <td className="gap"></td>
                                        <td><label htmlFor="email">Your Email</label></td>
                                    </tr>
                                    <tr>
                                        <td><input name="name" className={errors.name ? "error-border" : ""} id="name" onChange={handleInputChange} placeholder="John Wick" type="text" maxLength="100" /></td>
                                        <td className="gap"></td>
                                        <td><input name="email" className={errors.email ? "error-border" : ""} id="email" onChange={handleInputChange} placeholder="john@mail.com" type="email" maxLength="100" /></td>
                                    </tr>
                                    <tr className="vgap"></tr>
                                    <tr>
                                        <td colSpan="3"><label htmlFor="msg">Your Message</label></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3"><textarea name="message" className={errors.message ? "error-border" : ""} id="msg" onChange={handleInputChange} rows="5" placeholder="What's in your mind?"></textarea></td>
                                    </tr>
                                    <tr className="vgap"></tr>
                                    <tr>
                                        <td align="center" colSpan="3"><button type="submit" className="btn-submit"><SendIcon />Submit</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>)
                    )
                    }

                </div>

            </div>
        </div>
    )
}

export default Contact;